import { DateRange } from 'common/components/DateRangePicker'
import { atom } from 'recoil'

const landingShrimpPriceDateRange = () => {
  const today = new Date()
  const sevenDaysAgo = new Date(today)
  sevenDaysAgo.setDate(today.getDate() - 7)

  return atom<DateRange>({
    key: 'landingShrimpPriceDateRangeState',
    default: {
      startDate: sevenDaysAgo,
      endDate: today,
    },
  })
}

export const landingShrimpPriceDateRangeState = landingShrimpPriceDateRange()

export const marketState = atom<number | null>({
  key: 'marketState',
  default: null,
})
