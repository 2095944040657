import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { getShrimpPriceKey } from 'common/constants/queryKeys'
import { formatDate } from 'common/utils/date'
import { useRecoilValue } from 'recoil'

import { getMarketSystems, getShrimpPrices, getShrimpSizes } from '.'
import { landingShrimpPriceDateRangeState, marketState } from '../stores/atom'

const ROWS_PER_PAGE = 10

export const useGetMarketSystems = () => {
  const speciesId = 1

  return useQuery({
    queryKey: ['markets'],
    queryFn: async () => {
      const { data } = await getMarketSystems(speciesId)
      return data.data
    },
    staleTime: Infinity,
  })
}

export const useGetShrimpSizes = () => {
  const marketId = useRecoilValue(marketState)

  return useQuery({
    queryKey: ['shrimp-sizes', marketId],
    queryFn: async () => {
      const { data } = await getShrimpSizes(marketId)
      return data.data
    },
    staleTime: Infinity,
    enabled: !!marketId
  })
}

export const useGetShrimpPrices = () => {
  const dateRange = useRecoilValue(landingShrimpPriceDateRangeState)
  const marketId = useRecoilValue(marketState)
  const startDate = formatDate(dateRange.startDate)
  const endDate = formatDate(dateRange.endDate)
  const initParams = {
    pageSize: ROWS_PER_PAGE,
    page: 1,
    startDate,
    endDate,
    marketId,
  }
  
  return useInfiniteQuery({
    queryKey: getShrimpPriceKey(startDate, endDate, marketId),
    queryFn: async ({ pageParam = initParams }) => {
      const { data } = await getShrimpPrices(pageParam)
      return {
        shrimpPrice: data.data?.shrimpPrices,
        nextPage: data.nextPage,
      }
    },
    getNextPageParam: (lastPage, _) => {
      if (lastPage.nextPage) {
        return { ...initParams, page: lastPage.nextPage }
      } else {
        return undefined
      }
    },
    staleTime: Infinity,
    enabled: !!marketId,
  })
}
