import BasicTable from 'common/components/BasicTable'
import DateRangePicker, { DateRange } from 'common/components/DateRangePicker'
import useMedia from 'common/hooks/useMedia'
import { createArrayOfNumber } from 'common/utils/array'
import { formatThaiDateNumeric } from 'common/utils/date'
import { fNumber } from 'common/utils/formatNumber'
import { useGetMarketSystems, useGetShrimpSizes } from 'modules/landing/api/queries'
import { useShrimpPrices } from 'modules/landing/api/selector'
import { ShrimpPrice } from 'modules/landing/api/types'
import PriceLabel from 'modules/landing/components/PriceLabel'
import { landingShrimpPriceDateRangeState, marketState } from 'modules/landing/stores/atom'
import { ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'

import { Box, Card, Container, Skeleton, Stack, TableCellProps, Typography } from '@mui/material'

import Page from 'components/Page'
import SearchBox from 'components/SearchBox'

const ShrimpPricePage = () => {
  const { t } = useTranslation('shrimpprice')

  const [dateRange, setDateRange] = useRecoilState(landingShrimpPriceDateRangeState)
  const [marketId, setMarketId] = useRecoilState(marketState)
  const { data: markets } = useGetMarketSystems()
  const { data, fetchNextPage, hasNextPage, isFetching } = useShrimpPrices()
  const isMobile = useMedia('md')

  useEffect(() => {
    if (markets) {
      setMarketId(markets[0].id)
    }
  }, [markets])

  const { data: shrimpSizesData } = useGetShrimpSizes()

  const onSearchChange = (input: string) => {
    const market = markets?.find((el) => el.name === input)
    if (market) {
      setMarketId(market.id)
    }
  }

  const onDateRangeChange = (dateRange: DateRange) => {
    setDateRange(dateRange)
  }

  const shrimpSizes = (shrimpSizesData ?? []).map((el) => {
    return {
      id: `size-id-${el.id}`,
      title: fNumber(el.size, { disabledDecimal: true }),
      align: 'center' as TableCellProps['align'],
      width: '100px',
    }
  })

  const groupColumns = [
    {
      row: 1,
      columns: [
        {
          id: '',
          title: '',
          colSpan: 1,
          width: '150px',
        },
        {
          id: '',
          title: t('column.header'),
          colSpan: shrimpSizes.length,
          width: `${150 + 100 * shrimpSizes.length}px`,
        },
      ],
    },
  ]

  const columns = [
    {
      id: 'date',
      title: t('date'),
      format: formatThaiDateNumeric,
      width: '150px',
    },
    ...shrimpSizes,
  ]

  const onLoadMoreRows = () => {
    if (hasNextPage) {
      fetchNextPage()
    }
  }

  const getPrices = (prices: ShrimpPrice[]) => {
    let priceMap: Record<string, ReactNode> = {}
    shrimpSizesData?.forEach((el) => {
      const price = prices.find((price) => price.shrimpSizeId === el.id)
      priceMap[`size-id-${el.id}`] = (
        <PriceLabel price={price?.price} difference={price?.priceDifference} />
      )
    })
    return priceMap
  }

  const createTableRows = (date: string, prices: ShrimpPrice[]) => ({
    date,
    ...getPrices(prices),
  })

  const rows = data.map((row) => createTableRows(row.date, row.prices))
  const currentMarket = markets?.find((m) => m.id === marketId)

  return (
    <Page
      title={t('shrimp.price.today')}
      description={t('meta.description')}
      keywords={t('meta.keywords')}
    >
      <Container maxWidth="lg" sx={{ px: 2, py: 8 }}>
        <Typography variant="h2" component="h1" color="primary">
          {t('shrimp.price.today')}
        </Typography>

        <Box py={4}>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? 'flex-start' : 'center'}
            spacing={4}
          >
            <Stack spacing={2} width={isMobile ? '100%' : '50%'}>
              <Typography variant="h5">{t('find.source')}</Typography>
              <SearchBox
                onChange={onSearchChange}
                options={(markets ?? []).map((el) => el.name)}
                value={currentMarket?.name}
              />
            </Stack>

            <Stack spacing={2} width={isMobile ? '100%' : '30%'}>
              <Typography variant="h5">{t('date')}</Typography>
              <DateRangePicker value={dateRange} onChange={onDateRangeChange} />
            </Stack>
          </Stack>

          <Card sx={{ mt: 4, overflow: 'auto', height: '420px' }}>
            {isFetching ? (
              <Stack spacing={2} p={2}>
                <Stack direction="row" spacing={2}>
                  <Skeleton variant="rectangular" width="100%" />
                </Stack>
                {createArrayOfNumber(10).map((val) => (
                  <Stack direction="row" spacing={2} key={val}>
                    <Skeleton width="10%" />
                    <Skeleton width="30%" />
                    <Skeleton width="20%" />
                    <Skeleton width="20%" />
                    <Skeleton width="20%" />
                  </Stack>
                ))}
              </Stack>
            ) : (
              <BasicTable
                columns={columns}
                groupColumns={groupColumns}
                rows={rows}
                onLoading={onLoadMoreRows}
                isLoading={isFetching}
              />
            )}
          </Card>
        </Box>
      </Container>
    </Page>
  )
}

export default ShrimpPricePage
