import useYupValidationResolver from 'common/hooks/useYupValidationResolver'
import { ImageFile } from 'common/types/image'
import { useMutatePatchUserInfo, useMutateUploadProfilePicture } from 'modules/user/api/mutation'
import { useUserInfo } from 'modules/user/api/queries'
import { UserInfoResponse } from 'modules/user/api/types'
import { enqueueSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { Box, Button, Stack } from '@mui/material'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'

import UploadAvatar from 'components/upload/UploadAvatar'

interface FormDataType {
  firstname: string
  lastname: string
  phone: string
  image: ImageFile | string | null
  address?: string
}

interface UserFormProps {
  prefilledData?: UserInfoResponse
  hidePermission?: boolean
}

const UserForm = ({ prefilledData, hidePermission }: UserFormProps) => {
  const { t } = useTranslation(['account', 'common'])
  const [editMode, setEditMode] = useState(false)
  const { refetch } = useUserInfo()
  const editInfoMutation = useMutatePatchUserInfo()
  const uploadProfileMutation = useMutateUploadProfilePicture()

  const schema = Yup.object().shape({
    firstname: Yup.string().required(t('require.user.name')),
    lastname: Yup.string().required(t('require.user.lastname')),
    phone: Yup.string().required(t('require.phone')),
    address: Yup.string(),
    image: Yup.mixed().nullable(),
  })

  const resolver = useYupValidationResolver(schema)

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormDataType>({
    defaultValues: {
      firstname: '',
      lastname: '',
      phone: '',
      address: '',
      image: null,
    },
    resolver,
  })

  useEffect(() => {
    if (prefilledData) {
      const nameList = prefilledData?.name.split(' ')

      reset((values) => ({
        ...values,
        firstname: nameList[0],
        lastname: nameList[1],
        phone: prefilledData.phone,
        address: prefilledData.address,
        image: prefilledData.pictureURL,
      }))
    }
  }, [prefilledData])

  const onSubmit: SubmitHandler<FormDataType> = async (data) => {
    let imgUrl = ''
    const photo = data.image
    if (photo && typeof photo !== 'string') {
      const formData = new FormData()
      formData.append('picture', photo)
      const { data } = await uploadProfileMutation.mutateAsync(formData)
      imgUrl = data.data
    } else {
      imgUrl = data.image as string
    }

    await editInfoMutation
      .mutateAsync(
        {
          name: `${data.firstname} ${data.lastname}`,
          address: data.address ?? '',
          pictureURL: imgUrl,
        },
        {
          onSuccess: () => {
            enqueueSnackbar(t('common:edit.success'), { variant: 'success' })

            refetch()
          },
        }
      )
      .finally(() => {
        setEditMode(false)
      })
  }

  const handleDropPhoto = (acceptedFiles: any[]) => {
    const file = acceptedFiles[0]
    if (file) {
      const photoFile = file
      photoFile.preview = URL.createObjectURL(file)
      setValue('image', photoFile)
    }
  }

  const fieldComponent = {
    firstName: (
      <Controller
        name="firstname"
        control={control}
        render={({ field }) => (
          <TextField
            required
            {...field}
            fullWidth
            type="text"
            label={t('firstname')}
            error={!!errors.firstname}
            helperText={errors.firstname ? errors.firstname.message : undefined}
            disabled={!editMode}
          />
        )}
      />
    ),
    lastName: (
      <Controller
        name="lastname"
        control={control}
        render={({ field }) => (
          <TextField
            required
            {...field}
            fullWidth
            type="text"
            label={t('lastname')}
            error={!!errors.lastname}
            helperText={errors.lastname ? errors.lastname.message : undefined}
            disabled={!editMode}
          />
        )}
      />
    ),
    phone: (
      <Controller
        name="phone"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            required
            fullWidth
            type="text"
            label={t('phone')}
            error={!!errors.phone}
            helperText={errors.phone ? errors.phone.message : undefined}
            disabled
          />
        )}
      />
    ),
    address: (
      <Controller
        name="address"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            type="text"
            multiline
            label={t('address')}
            error={!!errors.address}
            helperText={errors.address ? errors.address.message : undefined}
            minRows={3}
            disabled={!editMode}
          />
        )}
      />
    ),
  }

  return (
    <form id="user-form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <Paper sx={{ minHeight: 120 }}>
        <Box textAlign="right">
          {editMode ? (
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <Button variant="outlined" onClick={() => setEditMode(false)}>
                {t('cancel')}
              </Button>
              <Button color="primary" variant="contained" form="user-form" type="submit">
                {t('save')}
              </Button>
            </Stack>
          ) : (
            <Button color="warning" variant="contained" onClick={() => setEditMode(true)}>
              {t('edit')}
            </Button>
          )}
        </Box>
        <Grid container item rowSpacing={3} xs={12}>
          <Grid item xs={12}>
            <UploadAvatar
              disabled={!editMode}
              accept="image/*"
              onDrop={(acceptedFiles) => handleDropPhoto(acceptedFiles)}
              file={watch('image')}
              sx={{ width: '144px', height: '144px' }}
            />
          </Grid>
          <Grid item container columnSpacing={2}>
            <Grid item xs={6}>
              {fieldComponent.firstName}
            </Grid>
            <Grid item xs={6}>
              {fieldComponent.lastName}
            </Grid>
          </Grid>
          <Grid item container columnSpacing={2}>
            <Grid item xs={12}>
              {fieldComponent.address}
            </Grid>
          </Grid>
          <Grid item container columnSpacing={2}>
            <Grid item xs={6}>
              {fieldComponent.phone}
            </Grid>
          </Grid>
          {!hidePermission && (
            <Grid item xs={12}>
              <Button color="primary" variant="outlined">
                {t('common:permission')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Paper>
    </form>
  )
}

export default UserForm
