import { BasePaginationResponseV2, BaseResponse } from 'common/types/api'
import axios from 'core/api/axios'

import { buildUrlWithParams } from 'utils/url'

import { GetShrimpPriceParams, ShrimpSize, ShrimpPriceGroup, Market } from './types'

export const getShrimpPrices = (params: GetShrimpPriceParams) =>
  axios.get<BasePaginationResponseV2<ShrimpPriceGroup>>(
    buildUrlWithParams('/v2/shrimp-price/group', {
      ...params,
    })
  )

export const getMarketSystems = (speciesId: number) =>
  axios.get<BaseResponse<Market[]>>(buildUrlWithParams('/v2/markets/system', { speciesId }))

export const getShrimpSizes = (marketId: number | null) =>
  axios.get<BaseResponse<ShrimpSize[]>>(buildUrlWithParams('/v2/shrimp-size', { marketId }))
